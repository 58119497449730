.route__login{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #082032;
    position: absolute;
    left: 0;
    top: 0;
}

.route__login__wrapper{
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #334756;
    padding: 20px;
    border-radius: 5px;
}

.route__login__wrapper input{
    width: 100%;
    height: 50px;
    outline: none;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

.route__login__wrapper div{
    background: #132b3d;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #EEEEEE;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
}

.route__login__wrapper > h1{
    font-size: 24px;
    color: #EEEEEE;
    font-weight: 400;
}

.route__login__wrapper > label{
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 3px;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 400;
    color: #EEEEEE;
}

@media screen and (max-width: 550px){
    .route__login__wrapper > label{
        font-size: 15px;
    }
    .route__login__wrapper > h1{
        font-size: 20px;
    }
}