.route__report {
    background: #334756;
    width: 100%;
    height: 100vh;

    &__header {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #132b3d;

        @media screen and (max-width: 1689px) {
            padding: 10px;
        }

        @media screen and (max-width: 1445px) {
            padding: 5px;
        }

        @media screen and (max-width: 1345px) {
            padding-right: 20px;
        }

        @media screen and (max-width: 550px) {
            padding-right: 10px;
        }

        &__title {
            font-size: 22px;
            color: whitesmoke;

            @media screen and (max-width: 1445px) {
                font-size: 20px;
            }
        }
    }

    &__wrapper {
        padding: 20px;
        overflow-y: scroll;
        max-height: calc(100% - 100px);

        @media screen and (max-width: 1445px) {
            padding: 10px;
        }

        @media screen and (max-width: 403px){
            padding: 5px;
        }

        @media screen and (max-width: 389px){
            padding: 10px;
        }

        &__table {
            width: 100%;

            &__head {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;
                background: #082032;
                padding: 5px;
                padding-top: 10px;
                border-radius: 5px 5px 0 0;

                @media screen and (max-width: 628px){
                    gap: 15px;
                }

                @media screen and (max-width: 431px){
                    gap: 10px;
                }

                @media screen and (max-width: 389px){
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                &__element {
                    border-bottom: 3px solid #082032;
                    cursor: pointer;

                    &>p {
                        color: #EEEEEE;
                        font-size: 18px;
                        @media screen and (max-width: 628px){
                            font-size: 16px;
                        }

                        @media screen and (max-width: 443px){
                            font-size: 14px;
                        }
                    }

                    &>input {
                        height: 25px;
                        padding-left: 5px;
                        font-size: 15px;
                        outline: none;
                        border: none;
                        background: #082032;
                        color: #EEEEEE;
                        border-bottom: 2px solid #EEEEEE;

                        @media screen and (max-width: 532px){
                            width: 100px;
                        }

                        @media screen and (max-width: 389px){
                            width: 100%;
                        }

                        &::placeholder {
                            color: #EEEEEE;
                            @media screen and (max-width: 532px){
                                font-size: 14px;
                            }
                        }
                    }
                }

                &__button {
                    background: #FF4C29;
                    color: #EEEEEE;
                    padding: 4px;
                    font-weight: 600;
                    border-radius: 5px;
                    font-size: 15px;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: 400ms;

                    @media screen and (max-width: 461px){
                        font-size: 13px;
                    }

                    &:hover {
                        box-shadow: 0 0 6px #FF4C29;
                    }
                }
            }

            &__campaign {

                &__info{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 30px;
                    padding: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background: #1c394e;
                    border-bottom: 5px solid #082032;
                    cursor: pointer;
    
                    @media screen and (max-width: 777px){
                        flex-direction: column;
                        gap: 15px;
                    }
    
                    &>p{
                        color: #EEEEEE;
                        font-size: 16px;
    
                        @media screen and (max-width: 628px){
                            font-size: 14px;
                        }
                    }
                }

                &__link{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 30px;
                    padding: 5px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background: #1c394e;
                    border-bottom: 5px solid #082032;

                    &>p{
                        color: #EEEEEE;
                    }
                }
            }
        }
    }
}