.route__campaigns {
    background: #334756;
    width: 100%;
    height: 100vh;

    &__header {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #132b3d;

        @media screen and (max-width: 1689px) {
            padding: 10px;
        }

        @media screen and (max-width: 1445px) {
            padding: 5px;
        }

        @media screen and (max-width: 1350px) {
            padding: 10px;
            padding-right: 20px;
        }

        @media screen and (max-width: 550px) {
            padding-right: 10px;
        }

        &__title {
            font-size: 22px;
            color: whitesmoke;

            @media screen and (max-width: 1445px) {
                font-size: 20px;
            }

            @media screen and (max-width: 550px) {
                font-size: 18px;
            }
        }

        &__button {
            border: none;
            width: fit-content;
            padding: 5px;
            height: 35px;
            border-radius: 5px;
            background: #FF4C29;
            color: #EEEEEE;
            font-weight: 600;
            font-size: 17px;
            cursor: pointer;
            transition: 400ms;

            &:hover {
                box-shadow: 0 0 12px #FF4C29;
            }

            @media screen and (max-width: 1445px) {
                font-size: 14px;
            }
        }
    }

    &__wrapper {
        padding: 20px;
        overflow-y: scroll;
        max-height: calc(100% - 100px);

        @media screen and (max-width: 1689px) {
            padding: 10px;
        }

        @media screen and (max-width: 1445px) {
            padding: 5px;
        }

        @media screen and (max-width: 1350px) {
            padding: 10px;
        }

        &__table {
            color: #EEEEEE;
            background: #1c394e;
            border-radius: 5px;

            &__header {
                display: grid;
                grid-template-columns: 0.5fr 2.5fr 1.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
                align-items: center;
                background: #082032;
                padding: 5px;
                border-radius: 5px 5px 0 0;
                height: 45px;

                @media screen and (max-width: 1689px) {
                    grid-template-columns: 0.3fr 2.5fr 1.5fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr;
                }

                &>p {
                    font-size: 17px;
                    text-align: center;

                    @media screen and (max-width: 1689px) {
                        font-size: 15px;
                    }

                    @media screen and (max-width: 1445px) {
                        font-size: 14px;
                    }
                }

                &__leftAlign {
                    text-align: left !important;
                }

                &__rightAlign {
                    text-align: right !important;
                }

                &__centerAlign {
                    text-align: center !important;
                }
            }

            &__data {
                display: grid;
                grid-template-columns: 0.5fr 2.5fr 1.8fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
                align-items: center;
                padding: 5px;
                border-bottom: 5px solid #082032;
                height: 45px;

                @media screen and (max-width: 1689px) {
                    grid-template-columns: 0.3fr 2.5fr 1.5fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr;
                }

                @media screen and (max-width: 1150px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    height: fit-content;
                    border-bottom: 10px solid #082032;
                }

                &>p {
                    font-size: 16px;

                    @media screen and (max-width: 1689px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: 1150px) {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                        height: fit-content;

                        &>p{
                            font-weight: 600;
                        }
                    }
                }

                &__centerAlign {
                    text-align: center !important;
                }

                &__button {
                    border: none;
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    background: #FF4C29;
                    color: #EEEEEE;
                    font-weight: 600;
                    font-size: 16px;
                    cursor: pointer;
                    transition: 400ms;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 1445px) {
                        width: 90%;
                        margin-left: auto;
                        margin-right: 0;
                        font-size: 14px;
                    }

                    @media screen and (max-width: 1150px) {
                        width: 100%;
                    }

                    &:hover {
                        box-shadow: 0 0 8px #FF4C29;
                    }
                }
            }
        }
    }

    &__createModal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.466);
        height: 100%;
        width: 100%;
        cursor: default;

        &__wrapper {
            min-width: 1000px;
            max-width: 1000px;
            height: fit-content;
            padding-bottom: 10px;
            background: #132b3d;
            border-radius: 5px;

            @media screen and (max-width: 1041px){
                min-width: 95%;
                max-width: 95%;
            }

            &__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px;
                background: #082032;

                &>h1 {
                    font-size: 22px;
                    font-weight: 400;
                    @media screen and (max-width: 634px){
                        font-size: 18px;
                    }
                }

                &>img {
                    width: 36px;
                    cursor: pointer;
                    @media screen and (max-width: 634px){
                        width: 28px;
                    }
                }
            }

            &__content {
                padding: 10px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                place-items: center;
                width: 100%;

                &__field {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    &>input {
                        width: 100%;
                        height: 40px;
                        outline: none;
                        background: whitesmoke;
                        border: none;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 16px;
                        border-radius: 5px;

                        @media screen and (max-width: 724px) {
                            font-size: 14px;
                        }
                    }

                    @media screen and (max-width: 634px){
                        &>label{
                            font-size: 14px;
                        }
                    }
                }

                &__dropdown {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                    position: relative;

                    &__head {
                        width: 100%;
                        background: whitesmoke;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        color: #082032;
                        padding-left: 10px;
                        padding-right: 10px;
                        cursor: pointer;
                        border-radius: 5px;
                        transition: border-radius 0.3s ease;

                        &>img {
                            width: 18px;
                        }
                    }

                    .activeDropdownHead {
                        border-radius: 5px 5px 0 0;
                    }

                    &__body {
                        z-index: 1000;
                        overflow: hidden;
                        max-height: 0px;
                        transition: max-height 0.3s ease;
                        background: whitesmoke;
                        width: 100%;
                        position: absolute;
                        top: 65px;
                        left: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        &>p {
                            color: #082032;
                            cursor: pointer;
                            transition: 400ms;
                            margin-left: 10px;
                            margin-right: 10px;
                            margin-top: 10px;

                            &:last-child {
                                margin-bottom: 10px;
                            }

                            &:hover {
                                color: #FF4C29;
                            }
                        }
                    }

                    .activeDropdown {
                        max-height: 480px;
                        border-radius: 0 0 5px 5px;
                    }

                }
            }

            &__domainDropdown {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                position: relative;
                padding: 10px;

                &__head {
                    width: 100%;
                    background: whitesmoke;
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    color: #082032;
                    padding-left: 10px;
                    padding-right: 10px;
                    cursor: pointer;
                    border-radius: 5px;
                    transition: border-radius 0.3s ease;

                    &>img {
                        width: 18px;
                    }
                }

                .activeDomainDropdownHead {
                    border-radius: 5px 5px 0 0;
                }

                &__body {
                    overflow: hidden;
                    max-height: 0px;
                    transition: max-height 0.3s ease;
                    background: whitesmoke;
                    width: calc(100% - 20px);
                    position: absolute;
                    top: 75px;
                    left: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    &>p {
                        color: #082032;
                        cursor: pointer;
                        transition: 400ms;
                        margin-left: 10px;
                        margin-right: 10px;
                        margin-top: 10px;

                        &:last-child {
                            margin-bottom: 10px;
                        }

                        &:hover {
                            color: #FF4C29;
                        }
                    }
                }

                .activeDomainDropdown {
                    max-height: 480px;
                    border-radius: 0 0 5px 5px;
                }

            }

            &__bottom {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;

                &__errorMsg {
                    color: #FF4C29;
                }

                &__btn {
                    background: #FF4C29;
                    width: fit-content;
                    padding: 5px;
                    height: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: 400ms;
                    margin-left: auto;
                    margin-top: 30px;

                    &>p {
                        font-size: 18px;
                        @media screen and (max-width: 634px){
                            font-size: 14px;
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px #FF4C29;
                    }
                }
            }
        }
    }

    &__detailsModal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.466);
        height: 100%;
        width: 100%;
        cursor: default;

        &__wrapper {
            min-width: 1000px;
            max-width: 1000px;
            height: fit-content;
            padding-bottom: 10px;
            background: #132b3d;
            border-radius: 5px;

            @media screen and (max-width: 1037px) {
                min-width: 90%;
                max-width: 90%;
            }

            @media screen and (max-width: 698px) {
                min-width: 95%;
                max-width: 95%;
            }

            &__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px;
                background: #082032;

                &>h1 {
                    font-size: 22px;
                    font-weight: 400;

                    @media screen and (max-width: 634px){
                        font-size: 18px;
                    }
                }

                &>img {
                    width: 36px;
                    cursor: pointer;

                    @media screen and (max-width: 634px){
                        width: 28px;
                    }
                }
            }

            &__errorMsg {
                color: #FF4C29;
            }

            &__content {
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;

                &__today {
                    width: 100%;
                    background: #334756;
                    border-radius: 5px;
                    max-height: fit-content;

                    &__header {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 5px;
                        background: #082032;
                        border-radius: 5px 5px 0 0;

                        &>h1 {
                            font-size: 18px;
                            font-weight: 400;

                            @media screen and (max-width: 634px){
                                font-size: 16px;
                            }
                        }
                    }

                    &__body {
                        overflow: hidden;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: 20px;
                        padding: 10px;

                        @media screen and (max-width: 552px){
                            grid-template-columns: 1fr 1fr;
                        }

                        @media screen and (max-width: 634px){
                            gap: 10px;
                        }

                        &__data {
                            background: #1c394e;
                            padding: 5px;
                            border-radius: 5px;

                            &__head {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: flex-start;
                                width: 100%;

                                &>p {
                                    font-weight: 600;

                                    @media screen and (max-width: 634px){
                                        font-size: 13px;
                                    }
                                }

                                &>img{
                                    @media screen and (max-width: 634px){
                                       width: 24px;
                                    }
                                }
                                
                            }

                            &>img {
                                margin-left: auto;
                                margin-right: 0;
                                display: block;
                            }

                            &>p {
                                font-weight: 400;
                            }
                        }
                    }
                }

                &__link {
                    background: #334756;
                    border-radius: 5px;
                    padding: 10px;
                    width: 100%;

                    &>p {
                        font-weight: 400;
                        cursor: text;
                    }
                }

                &__spending {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    background: #334756;
                    padding: 10px;
                    border-radius: 5px;
                    gap: 100px;

                    @media screen and (max-width: 634px){
                        gap: 20px;
                    }

                    @media screen and (max-width: 400px){
                        gap: 10px;
                    }

                    &__field {
                        width: 100%;

                        @media screen and (max-width: 634px){
                            font-size: 13px;
                        }

                        &>input {
                            width: 100%;
                            margin-top: 5px;
                            height: 35px;
                            border-radius: 5px;
                            outline: none;
                            background: #EEEEEE;
                            border: none;
                            font-size: 16px;
                            padding-left: 5px;
                            padding-right: 5px;

                            @media screen and (max-width: 724px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &__confirmed {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    background: #334756;
                    padding: 10px;
                    border-radius: 5px;
                    gap: 100px;

                    @media screen and (max-width: 634px){
                        gap: 20px;
                    }

                    @media screen and (max-width: 400px){
                        gap: 10px;
                    }

                    &__field {
                        width: 100%;

                        @media screen and (max-width: 634px){
                            font-size: 13px;
                        }

                        &>input {
                            width: 100%;
                            margin-top: 5px;
                            height: 35px;
                            border-radius: 5px;
                            outline: none;
                            background: #EEEEEE;
                            border: none;
                            font-size: 16px;
                            padding-left: 5px;
                            padding-right: 5px;

                            @media screen and (max-width: 724px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &__buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                    @media screen and (max-width: 634px){
                        gap: 5px;
                    }

                    &__btn {
                        width: 100%;
                        background: #FF4C29;
                        color: #EEEEEE;
                        font-size: 18px;
                        font-weight: 600;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                        border-radius: 5px;
                        cursor: pointer;
                        transition: 400ms;

                        &:hover {
                            box-shadow: 0 0 12px #FF4C29;
                        }

                        @media screen and (max-width: 634px){
                            font-size: 15px;
                        }

                        @media screen and (max-width: 436px){
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    &__pixelModal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.466);
        height: 100%;
        width: 100%;
        cursor: default;

        &__wrapper {
            width: 500px;
            height: fit-content;
            padding-bottom: 10px;
            background: #132b3d;
            border-radius: 5px;

            @media screen and (max-width: 634px){
                width: 85%;
            }

            &__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px;
                background: #082032;

                &>h1 {
                    font-size: 22px;
                    font-weight: 400;

                    @media screen and (max-width: 634px){
                        font-size: 18px;
                    }
                }

                &>img {
                    width: 36px;
                    cursor: pointer;
                    @media screen and (max-width: 634px){
                        width: 28px;
                    }
                }
            }

            &__content {
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 20px;

                &__box {
                    background: #334756;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 10px;
                    padding: 10px;

                    &>code {
                        font-weight: 400;
                        font-size: 14px;

                        @media screen and (max-width: 600px){
                            word-wrap: break-word;
                            overflow-wrap: break-word;
                            width: 100%;
                            font-size: 13px;
                        }
                    }

                    @media screen and (max-width: 600px){
                        width: 100%;
                    }
                }
            }
        }
    }
}