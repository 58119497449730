.route__users {
    background: #334756;
    width: 100%;
    height: 100vh;

    &__header {
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #132b3d;

        @media screen and (max-width: 724px) {
            padding: 10px;
        }

        &__title {
            font-size: 22px;
            color: whitesmoke;

            @media screen and (max-width: 724px) {
                font-size: 18px;
            }
        }

        &__button {
            border: none;
            width: fit-content;
            padding: 5px;
            height: 35px;
            border-radius: 5px;
            background: #FF4C29;
            color: #EEEEEE;
            font-weight: 600;
            font-size: 17px;
            cursor: pointer;
            transition: 400ms;

            @media screen and (max-width: 724px) {
                font-size: 14px;
            }

            &:hover {
                box-shadow: 0 0 12px #FF4C29;
            }
        }
    }

    &__wrapper {
        padding: 20px;
        overflow-y: scroll;
        max-height: calc(100% - 100px);

        @media screen and (max-width: 724px) {
            padding: 10px;
        }

        &__table {
            color: #EEEEEE;
            background: #1c394e;
            border-radius: 5px;

            &__header {
                display: grid;
                grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 5fr 2fr 2fr;
                align-items: center;
                background: #082032;
                padding: 5px;
                border-radius: 5px 5px 0 0;
                height: 45px;

                @media screen and (max-width: 724px) {
                    display: none;
                }

                &>p {
                    font-size: 17px;
                    text-align: center;
                }

                &__leftAlign {
                    text-align: left !important;
                }

                &__rightAlign {
                    text-align: right !important;
                }

                &__centerAlign {
                    text-align: center !important;
                }
            }

            &__data {
                display: grid;
                grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 5fr 2fr 2fr;
                align-items: center;
                padding: 5px;
                border-bottom: 5px solid #082032;
                height: 45px;

                @media screen and (max-width: 1045px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    height: fit-content;
                    gap: 10px;
                }

                &>p {
                    font-size: 16px;

                    @media screen and (max-width: 724px) {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                    }

                    @media screen and (max-width: 724px) {
                        font-size: 14px;
                    }
                }

                &__centerAlign {
                    text-align: center !important;
                }

                &__button {
                    border: none;
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    background: #FF4C29;
                    color: #EEEEEE;
                    font-weight: 600;
                    font-size: 16px;
                    cursor: pointer;
                    transition: 400ms;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        box-shadow: 0 0 8px #FF4C29;
                    }
                }
            }
        }
    }

    &__createModal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.466);
        height: 100%;
        width: 100%;
        cursor: default;

        &__wrapper {
            min-width: 700px;
            max-width: 700px;
            height: fit-content;
            padding-bottom: 10px;
            background: #132b3d;
            border-radius: 5px;

            @media screen and (max-width: 724px) {
                min-width: 95%;
                max-width: 95%;
            }

            &__header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px;
                background: #082032;

                &>h1 {
                    font-size: 22px;
                    font-weight: 400;
                    @media screen and (max-width: 724px) {
                        font-size: 18px;
                    }
                }

                &>img {
                    width: 36px;
                    cursor: pointer;
                    @media screen and (max-width: 724px) {
                        width: 28px;
                    }
                }
            }

            &__content {
                padding: 10px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                place-items: center;
                width: 100%;

                &__field {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;

                    @media screen and (max-width: 724px) {
                        &>label{
                            font-size: 14px;
                        }
                    }

                    &>input {
                        width: 100%;
                        height: 40px;
                        outline: none;
                        background: #EEEEEE;
                        border: none;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 16px;
                        border-radius: 5px;

                        @media screen and (max-width: 724px) {
                            font-size: 14px;
                        }
                    }

                    &__options {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                        background: #EEEEEE;
                        width: 100%;
                        height: 40px;
                        border-radius: 5px;
                        padding-left: 5px;

                        &>p {
                            color: #082032;
                            font-size: 18px;
                            text-transform: uppercase;
                            cursor: pointer;
                            padding: 3px;
                            border-radius: 5px;
                        }
                    }
                }
            }

            &__bottom {
                padding: 10px;

                &__btn {
                    background: #FF4C29;
                    width: 100%;
                    padding: 5px;
                    height: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: 400ms;
                    margin-left: auto;

                    &>p {
                        font-size: 18px;
                        @media screen and (max-width: 724px) {
                            font-size: 16px;
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px #FF4C29;
                    }
                }
            }
        }
    }
}