.component__sidebar {
    background: #082032;
    color: #EEEEEE;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
    padding: 20px;
    padding-top: 10px;
    position: relative;

    @media screen and (max-width: 944px){
        flex-direction: row;
        justify-content: space-between;
    }

    &__mobileMenu {
        position: absolute;
        left: 0;
        top: -700px;
        overflow: hidden;
        max-height: 0px;
        background: #082032;
        width: 100%;
        padding: 10px;
        transition: all 0.4s ease;
        z-index: 9999999;

        &__logo {
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &>h1 {
                font-size: 35px;
                font-family: "Libre Barcode 39 Text", system-ui;
                font-weight: 400;
                font-style: normal;
            }
        }

        &__nav {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            &__element {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                &>img {
                    width: 24px;
                }

                &>p{
                    font-size: 15px;
                }
            }
        }

        &__userInfo {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 100%;
            background: #132b3d;
            border: 2px solid #334756;
            padding: 5px;
            border-radius: 5px;
            margin-top: 30px;

            &>p{
                font-size: 14px;
            }

            &__profileImg {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            &__seemore {
                width: 20px;
                position: absolute;
                right: 0px;
                top: 0px;
                border-left: 2px solid #334756;
                border-bottom: 2px solid #334756;
                padding: 2px;
                cursor: pointer;
            }

            &__logout {
                position: absolute;
                display: none;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                background: #132b3d;
                padding: 7px;
                border-radius: 5px;
                box-shadow: 0 0 8px #082032;
                border: 2px solid #334756;
                overflow: hidden;
                animation: animateLogout .2s ease-in-out;
                cursor: pointer;
                z-index: 9999999999999999;
                right: 0;
                top: -50px;

                &>img {
                    width: 24px;
                }

                &>p {
                    font-size: 14px;
                }

                @keyframes animateLogout {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .activeMenu {
        max-height: 500px;
        top: 0;
    }

    &__hamburger {
        display: none;

        @media screen and (max-width: 944px) {
            display: block;
        }
    }

    @media screen and (max-width: 1445px) {
        padding: 10px;
    }

    @media screen and (max-width: 1350px) {
        flex-direction: row;
        gap: 30px;
        position: static;
        align-items: center;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 10px;

        @media screen and (max-width: 1350px) {
            width: fit-content;
        }

        @media screen and (max-width: 550px) {
            padding-top: 0;
        }

        &>h1 {
            font-size: 35px;
            font-family: "Libre Barcode 39 Text", system-ui;
            font-weight: 400;
            font-style: normal;

            @media screen and (max-width: 1445px) {
                font-size: 28px;
            }

            @media screen and (max-width: 550px) {
                font-size: 35px;
            }
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        width: 100%;

        @media screen and (max-width: 1350px) {
            flex-direction: row;
            align-items: center;
        }

        @media screen and (max-width: 944px) {
            display: none;
        }

        &__element {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            width: 100%;

            &>p {
                font-weight: 400;

                @media screen and (max-width: 1445px) {
                    font-size: 14px;
                }
            }
        }

        .active {
            border-left: 5px solid #FF4C29;
            padding-left: 5px;
        }
    }

    &__userInfo {
        position: absolute;
        bottom: 20px;
        left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: calc(100% - 40px);
        background: #132b3d;
        border: 2px solid #334756;
        padding: 5px;
        border-radius: 5px;

        @media screen and (max-width: 1445px) {
            left: 10px;
            width: calc(100% - 20px);

            &>p {
                font-size: 14px;
            }
        }

        @media screen and (max-width: 1350px) {
            position: relative;
            top: auto;
            bottom: auto;
            right: auto;
            left: auto;
        }

        @media screen and (max-width: 944px) {
            display: none;
        }

        &__profileImg {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            @media screen and (max-width: 1445px) {
                width: 40px;
                height: 40px;
            }
        }

        &__seemore {
            width: 24px;
            position: absolute;
            right: 0px;
            top: 0px;
            border-left: 2px solid #334756;
            border-bottom: 2px solid #334756;
            padding: 2px;
            cursor: pointer;

            @media screen and (max-width: 1445px) {
                width: 20px;
            }
        }

        &__logout {
            position: absolute;
            right: -120px;
            display: none;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            background: #132b3d;
            padding: 7px;
            border-radius: 5px;
            top: 0;
            box-shadow: 0 0 8px #082032;
            overflow: hidden;
            animation: animateLogout .2s ease-in-out;
            cursor: pointer;
            z-index: 100000;

            @media screen and (max-width: 1350px) {
                top: 30px;
                right: 0;
                height: fit-content;
            }

            &>img {
                width: 24px;
            }

            &>p {
                font-size: 15px;

                @media screen and (max-width: 1445px) {
                    font-size: 14px;
                }
            }

            @keyframes animateLogout {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
}