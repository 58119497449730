*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
  font-family: 'Roboto';
}

.route__contentWrapper{
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root{
  min-height: 100vh;
  background: #334756;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #F3F3F3;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb9b9;
}

.route__contentWrapper__body{
  display: grid;
  grid-template-columns: 220px 1fr;
  min-height: 100vh;
}

@media screen and (max-width: 1600px){
  .route__contentWrapper__body{
    grid-template-columns: 170px 1fr;
  }
}

@media screen and (max-width: 1350px){
  .route__contentWrapper__body{
    grid-template-columns: 1fr;
  }
}